<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="isRegisterPage ? i18n.$t('login.register') : isPasswordResetPage ? (showSetNewPassword ? i18n.$t('login.set') : i18n.$t('login.reset')) : i18n.$t('login.login')" />
    </ion-header>
    <ion-content v-if="!isPasswordResetPage && unlockUserNeeded" class="form-content">
      <div id="form-container">
        <ion-card v-if="!isConfirmed" color="warning">
          <ion-card-content>
            {{ i18n.$t('login.not_confirmed.message') }}
          </ion-card-content>
          <ion-item lines="none" color="warning">
            <ProgressButton slot="end" @click="sendNewConfirmationEmail()" color="light" :loading="confirmationEmailLoading">
              {{ i18n.$t('login.not_confirmed.action') }}
            </ProgressButton>
          </ion-item>
        </ion-card>
        <ion-card v-if="passwordResetEnforced" color="warning">
          <ion-card-content>
            {{ i18n.$t('login.reset_enforced.message') }}
          </ion-card-content>
          <ion-item lines="none" color="warning">
            <ProgressButton slot="end" @click="loggedInResetPassword()" color="light" :loading="passwordResetLoading">
              {{ i18n.$t('login.reset_enforced.action') }}
            </ProgressButton>
          </ion-item>
        </ion-card>
        <div id="confirm-continue">
          <ion-label>{{ i18n.$t('login.continue.title') }}</ion-label>
          <ProgressButton color="success" @click="refreshUserStatus()" :loading="userInfoLoading">
            {{ i18n.$t('login.continue.action') }}
            <ion-icon slot="end" :icon="enterOutline"></ion-icon>
          </ProgressButton>
        </div>
      </div>
    </ion-content>

    <ion-content v-else class="form-content">
      <div class="information-outer-container">
        <ion-card v-if="willRedirect" class="information-container">
          <ion-icon
            :icon="linkOutline" 
            size="large"
            color="primary">
          </ion-icon>
          <div>{{ i18n.$t('login.redirect') }}</div>
        </ion-card>
      </div>

      <div id="form-container">
        <form
          @submit="$event.preventDefault(); if (isRegisterPage) register(); else if (isPasswordResetPage) resetPassword(); else login('login');"
          ref="formInstance">

          <p v-if="!isRegisterPage" id="intro-offer">
            {{ i18n.$t('login.intro_offer.before') }}
            <a :href="`${websiteBaseURL}mail/info`">
              {{ i18n.$t('login.intro_offer.contact') }}
              <ion-icon class="inline-icon" :icon="at"></ion-icon>
            </a>
            {{ i18n.$t('login.intro_offer.after') }}
          </p>

          <a v-if="isRegisterPage" href="javascript:void(0);" @click.prevent="goToPathWithRedirect('/login')">{{ i18n.$t('login.links.login') }}</a>
          <a v-else-if="!isPasswordResetPage" href="javascript:void(0);" @click.prevent="goToPathWithRedirect('/register')">{{ i18n.$t('login.links.register') }}</a>
          <ion-item v-if="!isPasswordResetPage" lines=full id="e-mail" class="input-item">
            <ion-label position="floating">{{ i18n.$t('login.email') }}</ion-label>
            <ion-input required type="email" name="email" autocomplete="email" v-model="email" :maxlength="maxInputLength"></ion-input> <!-- TODO Check for correct email syntax on server. Same for password reset mails. Keep track on server which email has been confirmed and which hasn't been. Maybe remind and delete unconfirmed accounts. -->
          </ion-item>
          <ion-item lines=full id="password" :class="[passwordsMatch ? '' : 'not-matching', 'input-item']">
            <ion-label position="floating">{{ i18n.$t('login.password') }}</ion-label>
            <ion-input required :type="passwordVisible ? 'text' : 'password'" name="password" :autocomplete="(isRegisterPage || isPasswordResetPage) ? 'new-password' : 'current-password'" clearOnEdit="false"
              @ionBlur="comparePasswordsForMistake()"
              v-model="password"
              :maxlength="maxInputLength"
              @keydown="(!(isRegisterPage || isPasswordResetPage)) ? checkForEnter($event, submitButton) : null"
              ref="passwordInput">
            </ion-input>
            <ion-button class="input-end" slot="end" fill="none" @click="passwordVisible = !passwordVisible" shape="round">
              <ion-icon slot="icon-only" :icon="passwordVisible ? eyeOff : eye"></ion-icon>
            </ion-button>
          </ion-item>

          <div v-if="isRegisterPage || isPasswordResetPage" id="password-strength-container">
            <div class="password-strength">
              <ion-progress-bar :value="passwordStrengthScore >= 0 ? 1 : 0" :color="passwordStrengthColor"></ion-progress-bar>
            </div>
            <div class="password-strength">
              <ion-progress-bar :value="passwordStrengthScore >= 1 ? 1 : 0" :color="passwordStrengthColor"></ion-progress-bar>
            </div>
            <div class="password-strength">
              <ion-progress-bar :value="passwordStrengthScore >= 2 ? 1 : 0" :color="passwordStrengthColor"></ion-progress-bar>
            </div>
            <div class="password-strength">
              <ion-progress-bar :value="passwordStrengthScore >= 3 ? 1 : 0" :color="passwordStrengthColor"></ion-progress-bar>
            </div>
            <div class="password-strength">
              <ion-progress-bar :value="passwordStrengthScore >= 4 ? 1 : 0" :color="passwordStrengthColor"></ion-progress-bar>
            </div>
          </div>
          
          <ion-item lines=full v-if="isRegisterPage || isPasswordResetPage" id="password-confirm" :class="[passwordsMatch ? '' : 'not-matching', 'input-item']">
            <ion-label position="floating">{{ i18n.$t('login.password-confirm') }}</ion-label>
            <ion-input required :type="passwordConfirmVisible ? 'text' : 'password'" name="password-confirm" autocomplete="off" clearOnEdit="false"
              @ionBlur="comparePasswordsForMistake()"
              v-model="passwordConfirm"
              :maxlength="maxInputLength"
              @keydown="checkForEnter($event, submitButton)">
            </ion-input>
            <ion-button class="input-end" slot="end" fill="none" @click="passwordConfirmVisible = !passwordConfirmVisible" shape="round">
              <ion-icon slot="icon-only" :icon="passwordConfirmVisible ? eyeOff : eye"></ion-icon>
            </ion-button>
          </ion-item>
          <p v-if="!passwordsMatch" class="not-matching">{{ i18n.$t('login.passwords_not_matching') }}</p>

          <a href="javascript:void(0);" v-if="!(isRegisterPage || isPasswordResetPage)" @click.prevent="openPasswordForgot()">{{ i18n.$t('login.links.forgot_password') }}</a> <!-- TODO Show modal dialog for password reset with a confirmation that a reset mail has been sent, after the server responds -->
          
          <ion-item v-if="isRegisterPage" id="register-as-veterinarian" class="input-item">
            <ion-checkbox slot="start" name="veterinarian" v-model="registerAsVeterinarian"></ion-checkbox>
            <ion-label>{{ i18n.$t('login.register_as_veterinarian') }}</ion-label>
          </ion-item>
          
          <p v-if="isRegisterPage" id="accept-agb-info">
            {{ i18n.$t('login.accept-agb.before_agb') }}
            <a :href="`${websiteBaseURL}agb`" @click.prevent="openHrefAsModal(`${websiteBaseURL}agb`, i18n.$t('settings.agb'))">{{ i18n.$t('login.accept-agb.agb') }}</a>
            {{ i18n.$t('login.accept-agb.after_agb') }}
            <br/>
            <br/>
            {{ i18n.$t('login.accept-agb.before_privacy') }}
            <a :href="`${websiteBaseURL}privacy`" @click.prevent="openHrefAsModal(`${websiteBaseURL}privacy`, i18n.$t('settings.privacy'))">{{ i18n.$t('login.accept-agb.privacy') }}</a>
            {{ i18n.$t('login.accept-agb.after_privacy') }}
          </p>
          <ion-buttons>
            <ProgressButton class="submit-button" ref="submitButton" expand="block" fill="solid" type="submit" color="primary" :loading="loginOrRegisterLoading">
              {{ 
                isRegisterPage ? i18n.$t('login.register') : 
                isPasswordResetPage ? (showSetNewPassword ? i18n.$t('login.set') : i18n.$t('login.reset')) :
                i18n.$t('login.login')
              }}
            </ProgressButton>
            <!-- TODO Maybe add social login later if it is possible with privacy -->
            <!-- <ion-button expand="block" fill="outline" @click="login('socialLoginFacebook')">
              <ion-icon slot="start" :icon="logoFacebook"></ion-icon>
              {{ i18n.$t('login.social.facebook') }}
            </ion-button>
            <ion-button expand="block" fill="outline" @click="login('socialLoginGoogle')">
              <ion-icon slot="start" :icon="logoGoogle"></ion-icon>
              {{ i18n.$t('login.social.google') }}
            </ion-button> -->
          </ion-buttons>
          <p v-if="!isRegisterPage" id="questions">
            {{ i18n.$t('login.questions.pre-release') }}
            <br/>
            <br/>
            {{ i18n.$t('login.questions.before') }}
            <a :href="`${websiteBaseURL}mail/info`">
              {{ i18n.$t('login.questions.contact') }}
              <ion-icon class="inline-icon" :icon="at"></ion-icon>
            </a>
            {{ i18n.$t('login.questions.after') }}
            <br/>
            {{ i18n.$t('login.questions.help') }}
          </p>
        </form>
      </div>
    </ion-content>

    <!-- Modal Dialogs -->
    <!-- TODO Add page for validating confirmation link and tell the user it was successfull or not. Timeout for confirmation and password reset are necessary so that the links are not valid forever. -->
    
    <!--TODO E-Mail Confirmation automatisch anzeigen, wenn User nicht bestätigt ist. Globales Popup für alle Fehlerzustände beim User erstellen! isAuthenticated dafür und für blocked anpassen! Und in beiden Fällen keinen Login zulassen mit Hinweis -->
    <ion-modal
      :is-open="confirmationEmailOpen"
      css-class="custom-login-messages-popup"
      backdrop-dismiss="false">
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ i18n.$t('login.register_confirmation.title') }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="confirmationEmailOpen = false" shape="round">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div class="popup-inner-content">
          <ion-item v-if="!confirmationEmailError">
            <ion-icon  class="icon-success" slot="start" :icon="mailUnread"></ion-icon>
            <p class="confirmation-text"> {{ i18n.$t('login.register_confirmation.success_text.before_email') }} <b>{{ email }}</b> {{ i18n.$t('login.register_confirmation.success_text.after_email') }} </p>
          </ion-item>
          <ion-item v-else>
            <ion-icon  class="icon-error" slot="start" :icon="mailUnread"></ion-icon>
            <p class="confirmation-text"> {{ i18n.$t('login.generic_error_text') }} </p>
          </ion-item>
        </div>
        
      </ion-page>
    </ion-modal>

    <ion-modal
      :is-open="passwordForgotOpen"
      css-class="custom-login-messages-popup"
      backdrop-dismiss="false">
      <ion-page>
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ i18n.$t('login.reset_password.title') }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closePasswordForgot()" shape="round">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <div class="popup-inner-content" v-if="!passwordForgotSent && !passwordForgotError">
          <form
          @submit="$event.preventDefault(); if($event.target.checkValidity()) forgotPassword();">
            <ion-item class="input-item">
              <ion-label position="floating">{{ i18n.$t('login.email') }}</ion-label>
              <ion-input required type="email" name="email" autocomplete="email" v-model="passwordForgotEmail" :maxlength="maxInputLength"></ion-input>
            </ion-item>
            <ion-buttons>
              <ProgressButton ref="resetButton" expand="block" fill="outline" type="submit" :loading="passwordResetLoading">{{ i18n.$t('login.reset_password.reset_action') }}</ProgressButton>
            </ion-buttons>
          </form>
        </div>
        <div class="popup-inner-content" v-else>
          <ion-item v-if="passwordForgotSent">
            <ion-icon  class="icon-success" slot="start" :icon="mailUnread"></ion-icon>
            <p class="confirmation-text"> {{ i18n.$t('login.reset_password.success_text.before_email') }} <b>{{passwordForgotEmail}}</b> {{ i18n.$t('login.reset_password.success_text.after_email') }} </p><!-- TODO Alle Interaktionen müssen eine zeitliche Grenze haben, die man überprüfen kann, damit niemand das System DOSen kann oder anderweitig zuspamt. Also z.B. loggen, wann von welcher Quelle was geschickt wurde und nach X Minuten wieder löschen und erst dann wieder einen Zugriff zulassen. -->
          </ion-item>
          <ion-item v-if="passwordForgotError">
            <ion-icon  class="icon-error" slot="start" :icon="mailUnread"></ion-icon>
            <p class="confirmation-text"> {{ i18n.$t('login.generic_error_text') }} </p>
          </ion-item>
        </div>
      </ion-page>
    </ion-modal>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonItem, IonLabel, IonInput, IonButtons, IonButton, IonIcon, IonProgressBar, IonModal, IonToolbar, IonTitle, IonCard, IonCardContent, IonCheckbox, onIonViewWillLeave, onIonViewWillEnter } from '@ionic/vue';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import MainToolbar from '@/components/MainToolbar.vue';
import ProgressButton from '@/components/ProgressButton.vue';

import { eye, eyeOff, mailUnread, close, enterOutline, linkOutline, at /*logoFacebook, logoGoogle*/ } from 'ionicons/icons';

import { useI18n } from '@/utils/i18n';
import { apiErrorToast } from '@/utils/error';

import { openIFrameModal, default as modalComponent } from '@/components/IFrameModal.vue';

import zxcvbn from 'zxcvbn';

export default  {
  name: 'Login',
  components: { IonHeader, IonContent, IonPage, IonItem, IonLabel, IonInput, IonButtons, IonButton, IonIcon, IonProgressBar, MainToolbar, IonModal, IonToolbar, IonTitle, IonCard, IonCardContent, IonCheckbox, ProgressButton },
  setup() {
    const websiteBaseURL = process.env.VUE_APP_WEBSITE_URL;

    const maxInputLength = 100;//TODO Limit input fields like email and password to very long strings on client and server

    /*const escapeRegex = function(string) {
      return string.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
    }*/

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const formInstance = ref(null);
    const passwordInput = ref(null);
    const submitButton = ref(null);
    const resetButton = ref(null);

    const email = ref('');
    const password = ref('');
    const passwordConfirm = ref('');
    const registerAsVeterinarian = ref(false);

    const passwordVisible = ref(false);
    const passwordConfirmVisible = ref(false);

    const passwordStrengthScore = ref(-1);
    const passwordStrengthColor = ref('danger');

    const passwordsMatch = ref(true);

    const isRegisterPage = computed(() => route.name === 'register');

    const isPasswordResetPage = computed(() => (route.name === 'reset-password' || route.name === 'set-password')); //TODO Redirect to login if code is not set

    const showSetNewPassword = computed(() => route.name === 'set-password');

    const willRedirect = computed(() => (route.query != null && route.query.redirect != null && route.query.redirect.length > 0));

    const confirmationEmailOpen = ref(false);
    const confirmationEmailError = ref(false);

    const passwordForgotOpen = ref(false);

    const passwordForgotEmail = ref('');
    const passwordForgotSent = ref(false);
    const passwordForgotError = ref(false);

    const unlockUserNeeded = computed(() => store.getters['auth/unlockUserNeeded']);
    const isConfirmed = computed(() => store.getters['auth/isConfirmed']);
    const passwordResetEnforced = computed(() => store.getters['auth/passwordResetEnforced']);

    const loginOrRegisterLoading = ref(false);
    const userInfoLoading = ref(false);
    const passwordResetLoading = ref(false);
    const confirmationEmailLoading = ref(false);

    const i18n = useI18n();

    const checkFormValidity = function(){
      return formInstance.value.checkValidity(); //It won't continue if an constraint error has been set. customErrors count, if the string of the error is not empty
    }

    const checkForEnter = function(event, button){
      //If enter key was pressed, submit the form
      if(event.keyCode == 13) {
        event.preventDefault();
        button.click();
      }
    }

    const register = function(){
      if (checkFormValidity() && password.value === passwordConfirm.value){ //Only continue, if set constraints are matched //TODO Check constraints on server aswell
        loginOrRegisterLoading.value = true;
        store.dispatch('auth/register', { user: email.value, password: password.value, veterinarian: registerAsVeterinarian.value }).then(
          /* Success */
          () => {
            confirmationEmailError.value = false;
          },
          /* Failure */
          () => {
            confirmationEmailError.value = true;
          }
        ).finally(() => {
          loginOrRegisterLoading.value = false;
          confirmationEmailOpen.value = true;
        });
      }
    }

    const sendNewConfirmationEmail = function(){
      email.value = store.getters['auth/getEmail'];
      confirmationEmailLoading.value = true;
      store.dispatch('auth/sendConfirmation').then(
        /* Success */
        () => {
          confirmationEmailError.value = false;
        },
        /* Failure */
        () => {
          confirmationEmailError.value = true;
        }
      ).finally(() => {
        confirmationEmailLoading.value = false;
        confirmationEmailOpen.value = true;
      });
    }

    const login = function(action){
      if (action === 'login'){ //Login with credentials
        if(checkFormValidity()){ //Only continue, if set constraints are matched
          loginOrRegisterLoading.value = true;
          store.dispatch('auth/login', { user: email.value, password: password.value })
            .catch((error) => apiErrorToast(i18n, error, true))
            .finally(() => loginOrRegisterLoading.value = false);
        }
      } else {
        loginOrRegisterLoading.value = true;
        store.dispatch('auth/${action}')
        .finally(() => loginOrRegisterLoading.value = false);
      }
    }

    const loggedInResetPassword = function(){
      passwordForgotEmail.value = store.getters['auth/getEmail'];
      passwordResetLoading.value = true;
      store.dispatch('auth/forgotPassword', passwordForgotEmail.value).then(
        /* Success */
        () => {
          passwordForgotSent.value = true;
        },
        /* Failure */
        () => {
          passwordForgotError.value = true; //TODO Embed Contact-Link in error message for assistance
        }
      ).finally(() => {
        passwordResetLoading.value = false;
        passwordForgotOpen.value = true;
      });
    }

    const forgotPassword = function(){
      passwordResetLoading.value = true;
      store.dispatch('auth/forgotPassword', passwordForgotEmail.value).then(
        /* Success */
        () => {
          passwordForgotSent.value = true;
        },
        /* Failure */
        () => {
          passwordForgotError.value = true; //TODO Embed Contact-Link in error message for assistance
        }
      )
      .finally(() => passwordResetLoading.value = false);
    }

    const resetPassword = function(){
      if (route.query.code && checkFormValidity() && password.value === passwordConfirm.value){ //Only continue, if set constraints are matched
        loginOrRegisterLoading.value = true;
        store.dispatch('auth/resetPassword', { code: route.query.code, password: password.value, passwordConfirmation: passwordConfirm.value }).then(
          /* Success */
          () => { //TODO Show success message and redirect to login
            router.replace('/login');
          },
          /* Failure */
          (error) => {
            apiErrorToast(i18n, error, true)
              .then(() => router.replace('/login'));
          }
        ).finally(() => loginOrRegisterLoading.value = false);
      }
    }

    //Mistakes are only checked for when leaving the form to not be annyoing
    const comparePasswordsForMistake = function(){
      passwordsMatch.value = (password.value.length === 0 || passwordConfirm.value.length === 0) || (password.value === passwordConfirm.value); //Test only if not empty
    }

    //Updates the meter for the password strength
    watch(password, (newPassword) => {
      if (isRegisterPage.value === true || isPasswordResetPage.value === true){
        if (newPassword.length === 0){ //Do not score empty password
          passwordStrengthScore.value = -1;
        } else {
          let strength = zxcvbn(newPassword);

          passwordStrengthScore.value = strength.score;
        }
        setPasswordMessage();
      }
    });
    watch(passwordStrengthScore, (newScore) => {
      if(newScore < 0){
        passwordStrengthColor.value = undefined;
      } else if(newScore <= 1){
        passwordStrengthColor.value = 'danger';
      } else if(newScore == 2) {
        passwordStrengthColor.value = 'warning';
      } else {
        passwordStrengthColor.value = 'success';
      }
    });


    //Corrections are checked for on change to indicate when it is correct
    watch([password, passwordConfirm], ([newPassword, newPasswordConfirm]) => {
      if (newPassword === newPasswordConfirm) {
        passwordsMatch.value = true;
      }
    });

    //Sets the message for passwords not meeting the strength requirement if this is the case
    const setPasswordMessage = function(){
      passwordInput.value.$el.getInputElement().then((inputElement) => {
        if(password.value.length > 0 && passwordStrengthScore.value < 2){ //If a password has been entered and the score is less than medium, reject it with an error
          inputElement.setCustomValidity(i18n.$t('login.password_requirements_not_matched')); //FIXME the other constraint popoups, because they are dependant on the browser language
        } else {
          inputElement.setCustomValidity(''); //If no custom error is present, reset the custom error text
        }
      });
    }

    const clearPasswordForgot = function(){
      passwordForgotEmail.value = '';
      passwordForgotSent.value = false;
      passwordForgotError.value = false;
    }

    const openPasswordForgot = function(){
      clearPasswordForgot();
      passwordForgotOpen.value = true;
    }

    const closePasswordForgot = function(){
      passwordForgotOpen.value = false;
    }

    const refreshUserStatus = function(){
      userInfoLoading.value = true;
      store.dispatch('auth/fetchUser').catch(() => {}).finally(() => userInfoLoading.value = false);
    }

    onIonViewWillEnter(() => {
      store.dispatch('auth/fetchUser').catch(() => console.log('User not logged in!'));
    });

    onIonViewWillLeave(() => {
      email.value = '';
      password.value = '';
      clearPasswordForgot();
      confirmationEmailError.value = false;
    });

    const goToPathWithRedirect = function(path) {
      let redirect;
      if (route.query != null && route.query.redirect != null && route.query.redirect.length > 0) redirect = route.query.redirect;
      router.replace({ path, query: { redirect } });
      //TODO If on register page and finished registering, will the redirect be still valid
    }

    const openHrefAsModal = function(href, title){
      openIFrameModal(modalComponent, title, href);
    }

    return {  
      store,
      i18n,
      isRegisterPage,
      unlockUserNeeded,
      isConfirmed,
      passwordResetEnforced,
      isPasswordResetPage,
      showSetNewPassword,
      loginOrRegisterLoading,
      userInfoLoading,
      passwordResetLoading,
      confirmationEmailLoading,
      checkForEnter,
      register,
      sendNewConfirmationEmail,
      login,
      comparePasswordsForMistake,
      formInstance,
      passwordInput,
      submitButton,
      resetButton,
      email,
      password,
      passwordConfirm,
      registerAsVeterinarian,
      passwordVisible,
      passwordConfirmVisible,
      passwordStrengthScore,
      passwordStrengthColor,
      passwordsMatch,
      maxInputLength,
      setPasswordMessage,
      confirmationEmailOpen,
      confirmationEmailError,
      passwordForgotOpen,
      openPasswordForgot,
      closePasswordForgot,
      passwordForgotEmail,
      loggedInResetPassword,
      forgotPassword,
      resetPassword,
      refreshUserStatus,
      passwordForgotSent,
      passwordForgotError,
      willRedirect,
      goToPathWithRedirect,
      websiteBaseURL,
      openHrefAsModal,
      /*logoFacebook,
      logoGoogle,*/
      eye,
      eyeOff,
      mailUnread,
      close,
      enterOutline,
      linkOutline,
      at
    };
  }
}
</script>

<style>
.custom-login-messages-popup {
  --height: auto;
  --width: auto;
  --max-width: 90%;
  --backdrop-opacity: 0.3;
  --border-radius: 5px;
}

.custom-login-messages-popup .ion-page {
  height: auto;
  position: relative;
  contain: content;
}

.custom-login-messages-popup ion-title {
  padding-inline: 20px;
}
</style>

<style scoped>
.form-content::part(scroll) {
  display: flex;
  padding: 1em;
}

#form-container, form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}

.input-end{
  color: var(--ion-color-medium);
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: auto;
  margin-top: auto;
  height: 35px;
  bottom: 0px;
  position: relative;
}

form .input-item, #accept-agb-info, #intro-offer, #questions {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#accept-agb-info {
  text-align: center;
  font-size: 0.85em;
}

#intro-offer {
  margin-bottom: 40px;
  font-weight: 600;
}

#questions {
  margin-top: 40px;
  font-size: 0.85em;
}

form .submit-button {
  text-transform: none;
  margin-top: 20px;
}

a:hover, a:active {
  text-decoration: underline;
}

a {
  text-decoration: none;
}

.not-matching {
  --color: var(--ion-color-danger);
  --background-color: var(--ion-color-danger);
  --highlight-color-focused: var(--ion-color-danger);
  color: var(--ion-color-danger);
  transition: ease all 0.5s;
}

#password-strength-container{
  position: relative;
  width: 100%;
  max-width: 300px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.password-strength{
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;
}

.icon-success {
  font-size: 64px;
  color: var(--ion-color-success-shade);
}

.icon-error {
  font-size: 64px;
  color: var(--ion-color-danger);
}

.popup-inner-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-inner-content form{
  margin-top: 5px;
  margin-bottom: 10px;
}

.confirmation-text {
  word-wrap: break-word; /* TODO Only break e-mail this way in a span element! */
  word-break: break-all;
  max-width: 500px;
  line-height: 1.5em;
}

ion-card {
  max-width: 400px;
  flex-shrink: 0;
}

#confirm-continue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

#confirm-continue > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

.information-outer-container {
  padding-left: 1em;
  padding-right: 1em;
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 1001;
}

.information-container {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  --background: rgba(var(--ion-card-background-rgb, 255,255,255), 0.9);
  --color: var(--ion-text-color, black);

  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
}

.information-container > * {
  opacity: 1!important;
  padding: 10px 0px 10px 10px;
}

.information-container ion-icon {
  min-width: 24px;
  max-width: 24px;
}

@media (orientation: landscape) {
  .information-container {
    width: 85%;
  }
}

.inline-icon {
  vertical-align: -0.2em;
}
</style>
